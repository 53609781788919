.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid #fadebf;
}

.image-gallery-thumbnail:hover {
  outline: none;
  border: 4px solid #ffd6c9;
}

.image-gallery-icon {
  filter: drop-shadow(0 0.5px 1px #1a1a1a);
}
.image-gallery-icon:hover {
  color: #ffd6c9;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: unset;
}
.gallery-wrapper {
  text-align: -webkit-center;
}

.image-gallery-bullets .image-gallery-bullet.active:hover {
  background: #ffd6c9;
  border: 1px solid #ffd6c9;
}
.image-gallery-bullets .image-gallery-bullet:hover {
  background: #ffd6c9;
  border: 1px solid #ffd6c9;
}

.image-gallery-slide {
  width: 33.33%;
}
.image-gallery-slides {
  display: flex;
}

@media screen and (min-width: 769px) {
  /* STYLES HERE */
  .image-gallery-slide {
    padding: 0 30px;
  }
  .image-gallery-slide-wrapper {
    width: 60vw;
    max-width: 1200px;
  }
}

.image-gallery-slide-wrapper {
  max-width: 1200px;
}
